<form class="form-horizontal" autocomplete="off" (ngSubmit)="onSubmit()">
  <div class="w-100 mb-4 text-xl">
    <span>{{ !isEditMode ? 'Create new campaign' : 'Modify campaign name' }}</span>
  </div>

  <div class="w-100">
    <mat-form-field class="w-100">
      <mat-label>New Campaign Name</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="newCampaignName"
        name="newCampaignName"
        required
        data-id="campaign-name-input-field" />
    </mat-form-field>
  </div>

  <button
    class="w-full mt-6"
    heyreachButton
    buttonType="primary"
    [disabled]="!isNameValid"
    data-id="create-campaign-button">
    <span>
      {{ isEditMode ? 'Modify' : 'Create' }}
    </span>
  </button>
</form>
