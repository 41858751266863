import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  ConnectedPosition,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CustomTemplateTooltipComponent } from './custom-template-tooltip.component';

@Directive({
  selector: '[customTemplateTooltip]',
})
export class CustomTemplateTooltipDirective implements OnInit, OnDestroy {
  @Input() tooltipTemplate: TemplateRef<any>;
  @Input() tooltipData: any;
  @Input() tooltipPosition: 'bottom' | 'end' = 'bottom';
  @Input() tooltipOffset: number = 5;
  @Input() delay: number = 0;
  private _overlayRef: OverlayRef;
  private hideTimeout: NodeJS.Timeout;
  private isHoveringTooltipTemplate = false;
  private showTooltip = false;

  constructor(
    private _overlay: Overlay,
    private _overlayPosition: OverlayPositionBuilder,
    private _elementRef: ElementRef,
    private _viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit() {
    this.initTooltipPosition();
  }

  initTooltipPosition() {
    let positions: ConnectedPosition[] = [];

    switch (this.tooltipPosition) {
      case 'end':
        positions = [
          {
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'center',
            offsetY: 0,
            offsetX: this.tooltipOffset,
          },
        ];
        break;
      default:
      case 'bottom':
        positions = [
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetY: this.tooltipOffset,
          },
        ];
        break;
    }

    const positionStrategy = this._overlayPosition
      .flexibleConnectedTo(this._elementRef)
      .withPositions(positions);

    this._overlayRef = this._overlay.create({
      positionStrategy,
    });
  }

  closeTooltip() {
    if (this.showTooltip || this.isHoveringTooltipTemplate) return;

    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }

  @HostListener('mouseenter')
  show() {
    this.showTooltip = true;
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const injector = this._createInjector();
      const customTooltipRef: ComponentRef<CustomTemplateTooltipComponent> =
        this._overlayRef.attach(
          new ComponentPortal(CustomTemplateTooltipComponent, null, injector),
        );
      customTooltipRef.instance.contentTemplate = this.tooltipTemplate;
      customTooltipRef.instance.tooltipData = this.tooltipData;
      customTooltipRef.location.nativeElement.addEventListener('mouseenter', () =>
        this.onMouseEnterTooltip(),
      );
      customTooltipRef.location.nativeElement.addEventListener('mouseleave', () =>
        this.onMouseLeaveTooltip(),
      );
    }
  }

  private _createInjector(): Injector {
    return Injector.create({
      providers: [{ provide: 'tooltipData', useValue: this.tooltipData }],
      parent: this._viewContainerRef.injector,
    });
  }

  @HostListener('mouseleave')
  hide() {
    this.showTooltip = false;
    this.closeTooltipWithDelay();
  }

  ngOnDestroy() {
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
      this.closeTooltip();
    }
    this.showTooltip = false;
    this.isHoveringTooltipTemplate = false;
  }

  onMouseEnterTooltip() {
    this.isHoveringTooltipTemplate = true;
  }

  onMouseLeaveTooltip() {
    this.isHoveringTooltipTemplate = false;
    this.closeTooltipWithDelay();
  }

  closeTooltipWithDelay() {
    this.hideTimeout = setTimeout(() => {
      this.closeTooltip();
    }, this.delay + 30);
  }
}
