import { Component, Input } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'dialog-header',
  templateUrl: './dialog-header.component.html',
  animations: [appModuleAnimation()],
})
export class DialogHeaderComponent {
  @Input() title: string = '';
  @Input() titleCentered: boolean = true;
}
