import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from '@shared/interceptors/abpHttpInterceptor';

import * as ApiServiceProxies from './service-proxies';
import * as FileServiceProxies from './file-download-proxies';

@NgModule({
  providers: [
    ApiServiceProxies.SessionServiceProxy,
    ApiServiceProxies.UserServiceProxy,
    ApiServiceProxies.RolesServiceProxy,
    ApiServiceProxies.TokenAuthServiceProxy,
    ApiServiceProxies.AccountServiceProxy,
    ApiServiceProxies.ConfigurationServiceProxy,
    ApiServiceProxies.InvitationServiceProxy,
    ApiServiceProxies.LinkedInAccountServiceProxy,
    ApiServiceProxies.LinkedInAccountSlotServiceProxy,
    ApiServiceProxies.LinkedInAccountSettingsServiceProxy,
    ApiServiceProxies.StripeServiceProxy,
    ApiServiceProxies.LinkedInUserListServiceProxy,
    ApiServiceProxies.LinkedInSearchServiceProxy,
    ApiServiceProxies.LinkedInMyNetworkServiceProxy,
    ApiServiceProxies.CampaignServiceProxy,
    ApiServiceProxies.InboxServiceProxy,
    ApiServiceProxies.LinkedInProfileTagServiceProxy,
    ApiServiceProxies.DashboardServiceProxy,
    ApiServiceProxies.CRMServiceProxy,
    ApiServiceProxies.WebhooksServiceProxy,
    ApiServiceProxies.SlackIntegrationServiceProxy,
    FileServiceProxies.ListFilesServiceProxy,
    ApiServiceProxies.EmailEnrichmentServiceProxy,
    ApiServiceProxies.MessageGenerationServiceProxy,
    ApiServiceProxies.SubscriptionServiceProxy,
    ApiServiceProxies.OnboardingServiceProxy,
    ApiServiceProxies.ZapierServiceProxy,
    ApiServiceProxies.MessageTemplateServiceProxy,
    ApiServiceProxies.CampaignSequenceTemplateServiceProxy,
    ApiServiceProxies.ApiKeyServiceProxy,
    ApiServiceProxies.AdministratorLinkedInAccountsServiceProxy,
    ApiServiceProxies.AdministrationTenantServiceProxy,
    ApiServiceProxies.AdministrationAccountPerformanceServiceProxy,
    ApiServiceProxies.AdministrationTeamMemberServiceProxy,
    ApiServiceProxies.WebhookImportIntegrationsServiceProxy,
    ApiServiceProxies.AdministrationCampaignServiceProxy,
    ApiServiceProxies.AdministrationCreditsServiceProxy,
    { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
  ],
})
export class ServiceProxyModule {}
