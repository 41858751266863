import {
  CampaignLeadProcessStatus,
  CampaignLeadStatus,
  CampaignSequenceErrorCode,
  CampaignStatus,
  ConversationType,
  CrmType,
  InMailStatus,
  LeadConnectionStatus,
  LeadMessageStatus,
  LinkedInAccountState,
  LinkedInAccountStateReason,
  LinkedInChallengeType,
  LinkedInPremiumPlans,
  LinkedInSearchStatus,
  LinkedInSearchType,
  LinkedInSendMessageFailCause,
  LinkedInUserListStatus,
  ListType,
  MessageType,
  OutreachToolUsedBefore,
  ReasignLeadSequenceInCampaignStatus,
  RetryLeadSequenceInCampaignStatus,
  SequenceNodeType,
  SubscriptionCancelReasonType,
  TenantAvailabilityState,
  UserInvitationResultType,
} from '@shared/service-proxies/service-proxies';

export class AppTenantAvailabilityState {
  static Available: number = TenantAvailabilityState._1;
  static InActive: number = TenantAvailabilityState._2;
  static NotFound: number = TenantAvailabilityState._3;
}

export class AppCampaignStatus {
  static DRAFT: number = CampaignStatus._0;
  static IN_PROGRESS: number = CampaignStatus._1;
  static IN_PROGRESS_TRAILING: number = CampaignStatus._2;
  static PAUSED: number = CampaignStatus._3;
  static FINISHED: number = CampaignStatus._4;
  static CANCELED: number = CampaignStatus._5;
  static FAILED: number = CampaignStatus._6;
  static LOCKED: number = CampaignStatus._7;
  static STARTING: number = CampaignStatus._8;
}

export enum AppCrmType {
  HUBSPOT = CrmType._0,
  PIPEDRIVE = CrmType._1,
  SALESFORCE = CrmType._2,
  GHL = CrmType._3,
  CLOSE = CrmType._4,
}

export class AppSequenceNodeType {
  static START: number = SequenceNodeType._0;
  static CONNECTION_REQUEST: number = SequenceNodeType._1;
  static MESSAGE: number = SequenceNodeType._2;
  static INMAIL: number = SequenceNodeType._3;
  static VIEW_PROFILE: number = SequenceNodeType._4;
  static FOLLOW: number = SequenceNodeType._5;
  static LIKE_POST: number = SequenceNodeType._6;
  static END: number = SequenceNodeType._7;
  static IS_CONNECTION: number = SequenceNodeType._8;
}

export class AppCampaignLeadStatus {
  static PENDING: number = CampaignLeadStatus._0;
  static FAILED: number = CampaignLeadStatus._1;
  static CONNECTION_SENT: number = CampaignLeadStatus._2;
  static CONNECTION_ACCEPTED: number = CampaignLeadStatus._3;
  static AWAITING_REPLY: number = CampaignLeadStatus._4;
  static REPLIED: number = CampaignLeadStatus._5;
  static MANUALLY_STOPPED_BY_USER: number = CampaignLeadStatus._6;
}

export class AppLeadMessageStatus {
  static NONE = LeadMessageStatus._0;
  static MESSAGE_SENT = LeadMessageStatus._1;
  static MESSAGE_REPLY = LeadMessageStatus._2;
}

export class AppLeadConnectionStatus {
  static NONE = LeadConnectionStatus._0;
  static CONNECTION_SENT = LeadConnectionStatus._1;
  static CONNECTION_ACCEPTED = LeadConnectionStatus._2;
}

export class AppCampaignLeadProcessStatus {
  static PENDING: number = CampaignLeadProcessStatus._0;
  static IN_SEQUENCE: number = CampaignLeadProcessStatus._1;
  static FINISHED: number = CampaignLeadProcessStatus._2;
  static PAUSED: number = CampaignLeadProcessStatus._3;
  static FAILED: number = CampaignLeadProcessStatus._4;
}

export class AppCampaignLeadErrorCode {
  static UNKNOWN: number = CampaignSequenceErrorCode._0;
  static USER_DELETED_FROM_LIST: number = CampaignSequenceErrorCode._1;
  static TOO_MANY_RETRIES: number = CampaignSequenceErrorCode._2;
  static CANNOT_SEND_MESSAGE_TIMEOUT: number = CampaignSequenceErrorCode._3;
  static FAILED_TO_ENRICH_TOO_MANY_RETRIES: number = CampaignSequenceErrorCode._5;
  static USER_BLOCKED_BY_RECEPIENT_CANNOT_SEND_INMAIL: number = CampaignSequenceErrorCode._6;
  static CANNOT_SEND_INMAIL_TIMEOUT: number = CampaignSequenceErrorCode._7;
  static ALREADY_A_CONNECTION: number = CampaignSequenceErrorCode._8;
  static TRYING_TO_RESEND_CONNECTION_REQUEST_TOO_SOON: number = CampaignSequenceErrorCode._9;
  static REMOVED_ACCOUNT_FROM_CAMPAIGN: number = CampaignSequenceErrorCode._10;
  static NO_INMAIL_CREDITS_REMAINING: number = CampaignSequenceErrorCode._11;
  static CANNOT_VIEW_PROFILE_OR_PROFILE_DOES_NOT_EXIST: number = CampaignSequenceErrorCode._12;
  static CANNOT_VIEW_USER_PROFILE: number = CampaignSequenceErrorCode._13;
  static FAILED_CONNECTION: number = CampaignSequenceErrorCode._14;
  static CANNOT_SEND_MESSAGE_TO_INDIRECT_CONNECTION: number = CampaignSequenceErrorCode._15;
  static DELETED: number = CampaignSequenceErrorCode._16;
  static DELETED_ACCOUNT: number = CampaignSequenceErrorCode._17;
  static REQUESTED_BY_USER: number = CampaignSequenceErrorCode._18;
  static UKNOWN_UPDATED_BY_ACCIDENT: number = CampaignSequenceErrorCode._19;
  static USER_BLOCKED_BY_RECIPIENT: number = CampaignSequenceErrorCode._20;
  static CUSTOM_PROXY_NOT_WORKING: number = CampaignSequenceErrorCode._21;
  static HEY_REACH_PROXY_NOT_WORKING: number = CampaignSequenceErrorCode._22;
  static CONNECTION_REQUEST_ALREADY_SENT: number = CampaignSequenceErrorCode._23;
  static FAILED_TO_CHECK_CONNECTION_STATUS: number = CampaignSequenceErrorCode._24;
  static FAILED_TO_CHECK_IF_CONVERSATION_EXISTS: number = CampaignSequenceErrorCode._25;
  static CONVERSATION_EXISTS: number = CampaignSequenceErrorCode._26;
  static FAILED_TO_CHECK_IF_CONVERSATION_HAS_REPLY: number = CampaignSequenceErrorCode._27;
  static LINKEDIN_ACCOUNT_EMAIL_NOT_VERIFIED: number = CampaignSequenceErrorCode._28;
  static EXCLUDED_LEAD_IN_OTHER_CAMPAIGN: number = CampaignSequenceErrorCode._29;
  static EXCLUDED_LEAD_IN_EXCLUDE_LIST: number = CampaignSequenceErrorCode._30;
  static EXCLUDED_LEAD_HAS_OTHER_CONVERSATION: number = CampaignSequenceErrorCode._31;
}

export class AppLinkedInSearchStatus {
  static IN_PROGRESS: number = LinkedInSearchStatus._0;
  static QUEUED: number = LinkedInSearchStatus._1;
  static FINISHED: number = LinkedInSearchStatus._2;
  static CANCELED: number = LinkedInSearchStatus._3;
  static FAILED: number = LinkedInSearchStatus._4;
}

export class AppLinkedInSearchType {
  static PEOPLE_SEARCH: number = LinkedInSearchType._0;
  static PEOPLE_SEARCH_URL: number = LinkedInSearchType._1;
  static SALES_NAVIGATOR_LEAD_SEARCH: number = LinkedInSearchType._2;
  static GROUP_SEARCH: number = LinkedInSearchType._3;
  static EVENT_SEARCH: number = LinkedInSearchType._4;
  static POST_REACTIONS: number = LinkedInSearchType._5;
  static SALES_NAVIGATOR_ACCOUNT_SEARCH: number = LinkedInSearchType._6;
  static SALES_NAVIGATOR_LEAD_SEARCH_FROM_ACCOUNT_LIST: number = LinkedInSearchType._7;
  static MY_NETWORK_SEARCH: number = LinkedInSearchType._8;
  static COMPANY_SEARCH: number = LinkedInSearchType._9;
  static RECRUITER_SEARCH: number = LinkedInSearchType._10;
}

export class AppMessageType {
  static Text: number = MessageType._0;
  static Gif: number = MessageType._1;
  static Attachment: number = MessageType._2;
  static Voice: number = MessageType._4;
}

export class AppListType {
  static USER_LIST: number = ListType._0;
  static COMPANY_LIST: number = ListType._1;
  static EVENT_LIST: number = ListType._2;
  static GROUP_LIST: number = ListType._3;
}

export class AppConversationType {
  static LINKEDIN_CONVERSATION: number = ConversationType._0;
  static LINKEDIN_INMAIL: number = ConversationType._1;
  static SALES_NAVIGATOR: number = ConversationType._2;
  static RECRUITER: number = ConversationType._3;
}

export class AppInMailStatus {
  static NOT_SENT: number = InMailStatus._0;
  static AWAITING_REPLY: number = InMailStatus._1;
  static ACCEPTED: number = InMailStatus._2;
}

export class AppLinkedInPremiumPlans {
  static PREMIUM: number = LinkedInPremiumPlans._0;
  static SALES_NAVIGATOR: number = LinkedInPremiumPlans._1;
  static RECRUITER: number = LinkedInPremiumPlans._2;
}

export class AppSSIScoreLabels {
  static PROFESSIONAL_BRAND: string = 'Establish your professional brand';
  static FIND_RIGHT_PEOPLE: string = 'Find the right people';
  static INSIGHT_ENGAGEMENT: string = 'Engage with insights';
  static STRONG_RELATIONSHIP: string = 'Build relationships';
}

export class AppLinkedInChallengeType {
  static PIN: number = LinkedInChallengeType._0;
  static ID: number = LinkedInChallengeType._1;
  static CAPTCHA: number = LinkedInChallengeType._2;
  static PROFILE_ISSUE: number = LinkedInChallengeType._3;
  static LINKEDIN_APP: number = LinkedInChallengeType._4;
  static AUTHENTICATOR: number = LinkedInChallengeType._5;
}

export class AppUserInvitationResultType {
  static SUCCESS: number = UserInvitationResultType._0;
  static UNKNOWN_ERROR: number = UserInvitationResultType._1;
  static USER_ALREADY_PART_OF_ORGANIZATION = UserInvitationResultType._2;
}

export class AppOutreachToolUsedBefore {
  static NONE: number = OutreachToolUsedBefore._0;
  static EXPANDI: number = OutreachToolUsedBefore._1;
  static WAALAXY: number = OutreachToolUsedBefore._2;
  static DRIPIFY: number = OutreachToolUsedBefore._3;
  static LINKED_HELPER: number = OutreachToolUsedBefore._4;
  static OTHER: number = OutreachToolUsedBefore._5;
}

export class AppLinkedInUserListStatus {
  static UNKNOWN: number = LinkedInUserListStatus._0;
  static PASSIVE: number = LinkedInUserListStatus._1;
  static IN_CAMPAIGN: number = LinkedInUserListStatus._2;
  static IN_PROGRESS: number = LinkedInUserListStatus._3;
  static QUEUED: number = LinkedInUserListStatus._4;
}

export class AppSubscriptionCancelReasonType {
  static TOO_EXPENSIVE: number = SubscriptionCancelReasonType._0;
  static DO_NOT_NEED_ANYMORE: number = SubscriptionCancelReasonType._1;
  static DIFFERENT_SOFTWARE: number = SubscriptionCancelReasonType._2;
  static BAD_RESULTS: number = SubscriptionCancelReasonType._3;
  static TEMPORARY: number = SubscriptionCancelReasonType._4;
  static OTHER: number = SubscriptionCancelReasonType._5;
}

export enum SLACK_EVENT_TYPE {
  PROXY_NOTIFICATION = 0,
  DISCONNECTED_ACCOUNT = 1,
  CONNECTION_REQUEST_ACCEPTED = 2,
  MESSAGE_REPLY_RECEIVED = 3,
  INMAIL_REPLY_RECEIVED = 4,
  CAMPAIGN_COMPLETED = 5,
}

export const SLACK_EVENT_TYPE_OPTIONS = [
  // {
  //   value: SLACK_EVENT_TYPE.PROXY_NOTIFICATION,
  //   label: 'Proxy Notification',
  // },
  // {
  //   value: SLACK_EVENT_TYPE.DISCONNECTED_ACCOUNT,
  //   label: 'Account disconnects',
  // },
  {
    value: SLACK_EVENT_TYPE.CONNECTION_REQUEST_ACCEPTED,
    label: 'Connection accepted',
  },
  {
    value: SLACK_EVENT_TYPE.MESSAGE_REPLY_RECEIVED,
    label: 'Message reply',
  },
  {
    value: SLACK_EVENT_TYPE.INMAIL_REPLY_RECEIVED,
    label: 'InMail reply',
  },
  {
    value: SLACK_EVENT_TYPE.CAMPAIGN_COMPLETED,
    label: 'Campaign completed',
  },
];

export enum AppLinkedInAccountState {
  Connecting = LinkedInAccountState._0,
  Connected = LinkedInAccountState._1,
  Disconnected = LinkedInAccountState._2,
  Challenged = LinkedInAccountState._3,
  ChallengeSolving = LinkedInAccountState._4,
  Fail = LinkedInAccountState._5,
  Syncing = LinkedInAccountState._6,
  NotSynced = LinkedInAccountState._7,
}

export enum AppLinkedInAccountStateReason {
  None = LinkedInAccountStateReason._0,
  Unauthorized = LinkedInAccountStateReason._1,
  UnknownError = LinkedInAccountStateReason._2,
  UnsupportedChallenge = LinkedInAccountStateReason._3,
  PinRequired = LinkedInAccountStateReason._4,
  PinExpired = LinkedInAccountStateReason._5,
  PinInvalid = LinkedInAccountStateReason._6,
  AuthenticatorPinRequired = LinkedInAccountStateReason._7,
  AuthenticatorPinExpired = LinkedInAccountStateReason._8,
  AuthenticatorPinInvalid = LinkedInAccountStateReason._9,
  InvalidCredentials = LinkedInAccountStateReason._10,
  AccountLocked = LinkedInAccountStateReason._11,
  TooManyRetries = LinkedInAccountStateReason._12,
  Timeout = LinkedInAccountStateReason._13,
  AccountRestriction = LinkedInAccountStateReason._14,
  ProxyIssue = LinkedInAccountStateReason._15,
}

export enum AppLinkedInSendMessageFailCause {
  UNKNOWN = LinkedInSendMessageFailCause._0,
  DUPLICATE_MESSAGE = LinkedInSendMessageFailCause._1,
  RECIPIENT_NOT_FIRST_DEGREE_CONNECTION = LinkedInSendMessageFailCause._2,
  USER_BLOCKED_BY_RECIPIENT = LinkedInSendMessageFailCause._3,
  DIFFERENT_SALES_NAVIGATOR = LinkedInSendMessageFailCause._4,
  ACCOUNT_DISCONNECTED = LinkedInSendMessageFailCause._5,
  NO_IN_MAIL_CREDITS_REMAINING = LinkedInSendMessageFailCause._6,
  MESSAGE_TIMEOUT = LinkedInSendMessageFailCause._7,
}

export enum AppRetryLeadSequenceInCampaignStatus {
  SUCCESS = RetryLeadSequenceInCampaignStatus._0,
  SEQUENCE_STATE_NOT_IN_FAILED_CONDITION = RetryLeadSequenceInCampaignStatus._1,
  NO_CAMPAIGN_ACCOUNT = RetryLeadSequenceInCampaignStatus._2,
  CAMPAIGN_ACCOUNT_INVALID = RetryLeadSequenceInCampaignStatus._3,
  CAMPAIGN_ACCOUNT_CANNOT_SEND_INMAILS = RetryLeadSequenceInCampaignStatus._4,
  SEQUENCE_NOT_FOUND = RetryLeadSequenceInCampaignStatus._5,
}

export enum AppReasignLeadSequenceInCampaignStatus {
  SUCCESS = ReasignLeadSequenceInCampaignStatus._0,
  ALREADY_ASSIGNED_TO_SENDER = ReasignLeadSequenceInCampaignStatus._1,
  PREVIOUSLY_ASIGNED_TO_SENDER = ReasignLeadSequenceInCampaignStatus._2,
  SEQUENCE_NOT_FOUND = ReasignLeadSequenceInCampaignStatus._3,
}
