import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InboxService } from '@shared/inbox-service/inbox.service';

@Pipe({
  name: 'secure',
})
export class SecurePipe implements PipeTransform {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private inboxService: InboxService,
  ) {}

  transform(url): Observable<SafeUrl> {
    // Check if the URL is already cached
    if (this.inboxService.attachmentsBlobCache[url]) {
      // If cached, return an observable with the cached value
      return new Observable((observer) => {
        observer.next(this.inboxService.attachmentsBlobCache[url]);
        observer.complete();
      });
    }

    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((blob) => {
        // Generate a safe URL
        const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
        // Cache the safe URL
        this.inboxService.attachmentsBlobCache[url] = safeUrl;
        // Return the safe URL
        return safeUrl;
      }),
    );
  }
}
