<!-- <p *ngIf="hasTitle">Selected account</p> -->
<mat-form-field class="w-full relative">
  <!-- Senders icon positioned closer to the placeholder text -->
  <img
    *ngIf="defaultAccountImage"
    class="w-6 mr-3 icon-size-5"
    src="assets/images/avatars/profile-circle.svg" />
  <img
    *ngIf="showSenderIcon"
    class="icon-size-5 absolute left-3"
    src="assets/icons/sidebar-icons/senders.svg" />
  <mat-select
    appearance="outline"
    [class.pl-6]="showSenderIcon"
    [placeholder]="placeholderLabel"
    data-id="linkedin-sselect-account"
    #singleSelect
    (selectionChange)="onSelectedAccountChange($event)"
    [multiple]="multiple"
    [ngModel]="multiple ? selectedAccounts : selectedAccounts?.[0]"
    [disabled]="disabled || accounts?.length == 0">
    <mat-option class="bg-third-background">
      <ngx-mat-select-search
        ngModel
        (ngModelChange)="searchTerm$.next($event)"
        closeSvgIcon="close"
        placeholderLabel="Search"
        noEntriesFoundLabel="No results found."></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngIf="allowNull" [value]="null">
      {{ nullOptionText }}
    </mat-option>
    <mat-option *ngFor="let account of filteredAccounts | async" [value]="account">
      <div class="flex">
        <div class="flex gap-2">
          <user-image-name
            [profile]="account.linkedInUserProfile"
            [emailAddress]="account.emailAddress"
            [showSubscriptionSmall]="false"></user-image-name>
        </div>
        <span class="relative top-1.5 h-5">
          <linkedin-account-search-locked-indicator
            [account]="account"></linkedin-account-search-locked-indicator>
        </span>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
